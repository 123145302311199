// preact
import { FunctionComponent } from 'preact'

// components
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'

const Item: FunctionComponent<{
	x: number, y: number, label?: string, isVisible: boolean, showNext: boolean, showBack: boolean,
	onNext: () => void, onBack: () => void, onClick: () => void, onClose: () => void,
}> = ({
	x, y, label = 'See Details', isVisible,
	showNext, showBack, onNext, onBack, onClick, onClose,
	children
}) => {
	const handleNextClick = (event: MouseEvent) => {
		event.stopPropagation()
		onNext()
	}

	const handleBackClick = (event: MouseEvent) => {
		event.stopPropagation()
		onBack()
	}

	return <li class="instructions-item" style={{ '--x-pos': x + '%', '--y-pos': y + '%' }} data-selected={isVisible}>
		<button onClick={onClick}>
			<span className="button-label">{label}</span>
		</button>
		<Tooltip isVisible={isVisible} positionBlock={y && y <= 50 ? 'bottom' : 'top'} positionInline={x && x <= 50 ? 'right' : 'left'}>
			{children}
			<div className="instructions-navigation">
				{showBack && <Button onClick={handleBackClick} type={'icon'} icon={'arrow_back'} importance={'tertiary'}>Back</Button>}
				<Button onClick={handleNextClick} type={'icon'} icon={'arrow_forward'}>Next</Button>
			</div>
		</Tooltip>
	</li>
}

export default Item
