// preact
import { FunctionComponent } from 'preact'

// mapbox
import mapboxgl from 'mapbox-gl'

// components
import Button from 'components/Button'

interface DownloadButtonProps {
	elRef: { current: HTMLCanvasElement | mapboxgl.Map | null }
	svgRef?: { current: SVGSVGElement | null }
	type: 'chart' | 'trend' | 'map'
	label?: string
	topic?: string
	event?: string
	year?: string
	disabled?: boolean
}

const source = 'trackingca'

const DownloadButton: FunctionComponent<DownloadButtonProps> = ({
	elRef, svgRef, type, label = 'Download',
	topic, event, year,
	disabled
}) => {

	const handleDownload = () => {
		const element = elRef.current
		const filename = [ topic, event, year, type, source ].filter(part => part !== undefined && part !== null).join('_') + '.png'
		const linkElement = document.createElement('a')

		const click = () => {
			linkElement.download = filename
			linkElement.click()
		}

		if (element instanceof HTMLCanvasElement && (type === 'chart' || type === 'trend')) {
			linkElement.href = element.toDataURL('image/png')
			click()
		} else if (element instanceof mapboxgl.Map && type === 'map') {
			const mapCanvas = element.getCanvas()
			const canvas = svgRef?.current ? document.createElement('canvas') : mapCanvas
			if (svgRef?.current) {
				canvas.width = mapCanvas.width
				canvas.height = mapCanvas.height
				const ctx = canvas.getContext('2d')
				if (ctx) {
					ctx.drawImage(mapCanvas, 0, 0, mapCanvas.width, mapCanvas.height)
					if (svgRef.current.firstElementChild instanceof SVGForeignObjectElement && svgRef.current.firstElementChild.firstElementChild instanceof HTMLDivElement) {
						svgRef.current.firstElementChild.firstElementChild.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml')
					}
					const svg = svgRef.current.outerHTML.replace(/<br>/g, '<br />')
					const img = new Image()
					img.addEventListener('load', () => {
						ctx.drawImage(img, mapCanvas.width - 640 - 24, 24)
						linkElement.href = canvas.toDataURL('image/png')
						click()
					})
					img.src = 'data:image/svg+xml;utf8,' + svg
				}
			}
		}

	}

	return <Button icon="download" importance="secondary" disabled={disabled} onClick={handleDownload}>{label}</Button>
}

export default DownloadButton
