// preact
import { createContext } from 'preact'
import { useState } from 'preact/hooks'

import { request } from './api'
import { Area } from './api/types'

export type AreasContext = {
	areas: Area[]
	list (): Promise<Area[]>
}

export default class AreasStores {

	static Context = createContext<AreasContext>({} as AreasContext)

	static values (): AreasContext {
		const [ areas, setAreas ] = useState<Area[]>([])

		return {
			areas,
			async list (): Promise<Area[]> {
				try {
					const json = await request({
						source: 'explorer',
						action: 'areas',
						data: {}
					})
					setAreas(json)
					return json
				} catch (_) {
					return []
				}
			}
		}
	}
}
