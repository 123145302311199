// preact
import { FunctionComponent } from 'preact'
import { useContext, useEffect } from 'preact/hooks'

// stores
import FiltersStore from 'stores/filters'
import TablesStore, { Sort } from 'stores/tables'

// components
import Empty from 'components/Empty'
import { BodyCell, HeaderCell } from 'components/Table'

import { TabComponent } from 'components/TabPanel'

const BodyRow: FunctionComponent<{
	geography: string, featured?: boolean, value: number | null, value1?: number | null, value2?: number | null, value3?: number | null
}> = ({
	geography, value, featured, value1, value2, value3
}) => {
	return <tr data-featured={featured}>
		<BodyCell>{geography}</BodyCell>
		<BodyCell numeric={true}>{value !== null ? Number.isInteger(value) ? value : value.toFixed(1) : 'N/A'}</BodyCell>
		{value1 !== undefined && <BodyCell numeric={true}>{value1 !== null ? Number.isInteger(value1) ? value1 : value1.toFixed(1) : 'N/A'}</BodyCell>}
		{value2 !== undefined && <BodyCell numeric={true}>{value2 !== null ? Number.isInteger(value2) ? value2 : value2.toFixed(1) : 'N/A'}</BodyCell>}
		{value3 !== undefined && <BodyCell numeric={true}>{value3 !== null ? Number.isInteger(value3) ? value3 : value3.toFixed(1) : 'N/A'}</BodyCell>}
	</tr>
}

const TableView: TabComponent = () => {
	const filtersStore = useContext(FiltersStore.Context)
	const tablesStore = useContext(TablesStore.Context)

	useEffect(() => {
		if (filtersStore.area && filtersStore.querySelected && tablesStore.sort) {
			tablesStore.table(filtersStore.area, filtersStore.selected, tablesStore.sort)
		}
	}, [ filtersStore.area, filtersStore.querySelected, tablesStore.sort ])

	const sorts = tablesStore.sort.split('_')
	const sortKey = sorts[0]
	const sortAsc = sorts[1] === 'asc'
	return <>{tablesStore.tables.length ?
		<table class="table">
			<thead>
			<tr>{Object.entries(tablesStore.labels).map(([ key, label ]) =>
				<HeaderCell key={key}
				            sort={key === sortKey ? sortAsc : undefined}
				            onSort={(ascending) => tablesStore.setSort(`${key}_${ascending ? 'asc' : 'dsc'}` as Sort)}
				>{label}</HeaderCell>
			)}</tr>
			</thead>
			<tbody>
			{tablesStore.tables.map(({ code, name, value, value1, value2, value3 }) =>
				<BodyRow key={code} geography={name} value={value} value1={value1} value2={value2} value3={value3} featured={code === '06'}/>
			)}
			</tbody>
		</table>
		: <Empty>Create a Query to View Table Data</Empty>}</>
}

export default TableView
