// domain
declare const DOMAIN: string

export const HOST = 'https://' + DOMAIN

export const request = async ({ source, action, data }: { source: string, action: string, data: { [p: string]: any } }) => {
	return await fetch(HOST, {
		method: 'POST',
		body: JSON.stringify({
			route: {
				source,
				action
			},
			data
		}),
		headers: { 'content-type': 'application/json' }
	}).then(response => {
		return Promise.all([ { code: response.status, text: response.statusText }, response.json() ])
	}).then(([ status, body ]) => {
		if (status.code >= 400) {
			throw new Error(body.errors?.error ?? `${status.code} ${status.text}`)
		}
		return body
	}).catch(error => {
		if (!error.errors) {
			error.errors = { error: 'Network connection failed, are you offline?' }
		}
		throw new Error(error.errors.error)
	})
}
