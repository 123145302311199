// preact
import { FunctionComponent } from 'preact'

const Footer: FunctionComponent<{  }> = ({  }) => {
	const year = new Date().getFullYear()

	return <footer className="app-footer">
		<p className="copy">©&nbsp;
			<time>{year}</time>
			&nbsp;<a href="https://trackingcalifornia.org" target="_blank">Tracking California</a></p>
	</footer>
}

export default Footer
