// preact
import { FunctionComponent } from 'preact'
import { useState } from 'preact/hooks'

// components
import Button from 'components/Button'

interface props {
	area?: string
	title?: string
	description?: string
	exportDataUrl?: string
	onAboutClick?: () => void
}

const PageHeader: FunctionComponent<props> = ({
	area, title, description, exportDataUrl,
	onAboutClick
}) => {
	const [ expanded, setExpanded ] = useState(false)

	const toggleExpansion = () => {
		setExpanded(!expanded)
	}

	return <header className="page-header">
		<hgroup className="page-header-content">
				<h3 className="page-title">{title || 'Choose a Content Area to Get Started'}</h3>
			{description && <div className="page-description">
				<p class="copy" aria-expanded={expanded}>{description}</p>
				<button onClick={toggleExpansion} className="description-toggle">{expanded ? 'Less' : 'More'}</button>
			</div>}
		</hgroup>
		<div class="page-header-actions">
			<Button onClick={onAboutClick} icon={'info'} side={'start'} disabled={!area} tooltip={'Learn more about the data'} positionInline={'right'} positionBlock={'bottom'}>
				{area ? <>
					About {area}
				</> : ''}
			</Button>
			{(area || title) && <div className="page-header-buttons" aria-expanded={expanded}>
				<Button icon="download" type={'icon'} to={exportDataUrl} tooltip={'Export Data'} positionInline={'left'} positionBlock={'bottom'}>Export Data</Button>
			</div>}
		</div>
	</header>
}

export default PageHeader
