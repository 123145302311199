import { FunctionComponent } from 'preact'

import Button from 'components/Button'

const Empty: FunctionComponent<{
	hidden?: boolean,
	background?: string,
	button?: { onClick?: () => void, label?: string, icon?: string }
}> = ({ hidden, background, button, children }) => {

	return <>
		{background ?
			<figure class="empty" aria-hidden={hidden}>
				<img class="empty-background" src={background} alt=""/>
				<figcaption class="empty-message">
					<span>{children}</span>
				</figcaption>
				{button && <Button onClick={button?.onClick} icon={button?.icon} importance={'secondary'}>{button?.label}</Button>}
			</figure> : <div class="empty" aria-hidden={hidden}>
				{children}
				{button && <Button onClick={button?.onClick} icon={button?.icon} importance={'secondary'}>{button?.label}</Button>}
			</div>
		}
	</>
}

export default Empty