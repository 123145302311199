// preact
import { createContext } from 'preact'
import { useState } from 'preact/hooks'

// api
import { request } from './api'
import { Table, Labels } from './api/types'
import { Filters } from './types'

export type Sort = 'name_asc' | 'name_dsc' | 'value_asc' | 'value_dsc' | 'value1_asc' | 'value1_dsc' | 'value2_asc' | 'value2_dsc' | 'value3_asc' | 'value3_dsc'

export type TablesContext = {
	area: string | null
	sort: Sort
	tables: Table[]
	labels: Labels
	clear: () => void
	setSort: (sort: Sort) => void
	table (area: string, filters: Filters, sort?: Sort): Promise<{ area: string | null, tables: Table[] }>
}

export default class TablesStore {

	static Context = createContext<TablesContext>({} as TablesContext)

	static values (): TablesContext {
		const [ area, setArea ] = useState<string | null>(null)
		const [ sort, setSort ] = useState<Sort>('name_asc')
		const [ tables, setTables ] = useState<Table[]>([])
		const [ labels, setLabels ] = useState<Labels>({ name: '', value: '' })

		return {
			area,
			sort,
			tables,
			labels,
			clear: () => {
				setArea(null)
				setSort('name_asc')
				setTables([])
				setLabels({ name: '', value: '' })
			},
			setSort,
			async table (area, filters, sort: Sort | null = null) {
				try {
					const json = await request({
						source: 'explorer',
						action: 'table',
						data: {
							area,
							filters,
							sort
						}
					})

					setArea(json.area)
					setSort(json.sort)
					setTables(json.data)
					setLabels(json.label)

					return { area, tables: json.data }
				} catch (_) {
					return { area: null, tables: [] }
				}
			}
		}
	}
}
