// preact
import { FunctionComponent, VNode, isValidElement, cloneElement } from 'preact'
import { useEffect, useState } from 'preact/hooks'

// components
import Tooltip, { useTooltip } from 'components/Tooltip'

type OptionProps = {
	group?: string
	value: string
	icon?: string
	iconOnly?: boolean
	checked?: boolean
	tooltip?: string
	onClick?: () => void
}

export const Option: FunctionComponent<OptionProps> = ({
	group, value,
	icon, iconOnly = false,
	checked,
	tooltip,
	onClick,
	children
}) => {
	const id = `to-${group}-${value}`

	const { isTooltipVisible, getTooltipBindings } = useTooltip()
	const tooltipBindings = tooltip ? getTooltipBindings() : {}

	return <div class="toggle-option">
		<input type="radio"
		       id={id} name={group}
		       value={icon}
		       checked={checked}
		       onClick={onClick}/>
		<label htmlFor={id} data-icon={icon} {...tooltipBindings}>
			<span className={icon && iconOnly ? 'sr-only' : undefined}>{children}</span>
		</label>
		{tooltip && <Tooltip positionBlock={'bottom'} isVisible={isTooltipVisible}>{tooltip}</Tooltip>}
	</div>
}

interface ToggleComponent<P = {}> extends FunctionComponent<P> {
	Option: typeof Option
}

export const Toggle: ToggleComponent<{
	group: string
	title?: string
	direction?: 'row' | 'column'
	selected?: string
	onSelect?: (id: string) => void
	children: VNode<OptionProps>[]
}> = ({
	group,
	title,
	direction = 'row',
	selected,
	onSelect,
	children
}) => {
	const [ _selected, setSelected ] = useState(selected)

	useEffect(() => {
		setSelected(selected)
	}, [ selected ])

	const onClick = (id: string) => {
		setSelected(id)
		onSelect && onSelect(id)
	}

	const index = children.findIndex(child =>
		isValidElement(child) && child.type === Option && child.props.value === _selected
	)

	const options = children.map((child, i) =>
		isValidElement(child) && child.type === Option ? cloneElement(child, {
			group,
			checked: child.props.value === _selected,
			onClick: () => onClick(child.props.value)
		}) : child
	)

	return <div className="toggle" style={{ '--count': options.length }} role="tablist" data-direction={title && direction ? direction : undefined}>
		{title && <p class="copy">{title}</p>}
		<ul class="toggle-options" data-index={index}>
			{options}
		</ul>
	</div>
}

Toggle.Option = Option

export default Toggle
