// preact
import { FunctionComponent } from 'preact'
import { useRef, useState, useEffect } from 'preact/hooks'

// components
import Tooltip, { useTooltip } from 'components/Tooltip'

type SelectorOptionProps = {
	group: string
	value: string
	label: string
	multiselect: boolean
	hidden: boolean
	checked: boolean
	disabled?: boolean
	irregular?: boolean
	tooltip?: string
	onChange: (e: Event) => void
}

const SelectorOption: FunctionComponent<SelectorOptionProps> = ({
	group, value, label, multiselect = false,
	hidden = false, checked = false,
	disabled, irregular = false,
	tooltip, onChange
}) => {
	const id = `so-${group}-${value}`

	const { isTooltipVisible, getTooltipBindings } = useTooltip()
	const tooltipBindings = tooltip ? getTooltipBindings() : {}

	return <li className="selector-option" aria-hidden={hidden} aria-disabled={disabled} data-irregular={irregular}>
		<input type={multiselect ? 'checkbox' : 'radio'}
		       id={id} name={group}
		       value={value}
		       checked={checked}
		       disabled={disabled}
		       onChange={onChange}/>
		<label htmlFor={id} {...tooltipBindings}>{label}</label>
		{tooltip && <Tooltip positionBlock="bottom" positionInline="right" isVisible={isTooltipVisible}>{tooltip}</Tooltip>}
	</li>
}

export type SelectorProps = {
	group: string
	title: string
	options?: { value: string, label: string, disabled?: boolean, irregular?: boolean, tooltip?: string }[]
	selected?: string[]
	onSelected?: (selected: string[]) => void
	onToggle?: (open: boolean) => void
	multiselect?: boolean
	disabled?: boolean
	caption?: string
	open?: boolean
	direction?: 'horizontal' | 'vertical'
}

const Selector: FunctionComponent<SelectorProps> = ({
	group, title, options = [],
	selected: _selected = [], onSelected, onToggle,
	multiselect = false,
	disabled, caption, open = false,
	direction = 'vertical'
}) => {
	const detailsRef = useRef<HTMLDetailsElement>(null)

	const [ selected, setSelected ] = useState<string[]>(_selected)
	useEffect(() => {
		setSelected(_selected)
	}, [ _selected.join('-') ])

	const onOptionChange = (option: string, checked: boolean) => {
		const selections = new Set(multiselect ? selected : [])
		if (checked) {
			selections.add(option)
		} else {
			selections.delete(option)
		}
		selectOption([ ...selections.keys() ])
	}

	const selectOption = (selected: string[]) => {
		setSelected(selected)
		if (onSelected) {
			onSelected(selected)
		}
		if (!multiselect) {
			const detailsEl = detailsRef.current
			if (detailsEl?.open) {
				shrink(detailsEl)
			}
		}
	}

	const onSummaryClick = (e: MouseEvent) => {
		e.preventDefault()
		const detailsEl = detailsRef.current
		if (!detailsEl) {
			return
		}
		if (detailsEl.open) {
			shrink(detailsEl)
			if (onToggle) {
				onToggle(false)
			}
		} else {
			expand(detailsEl)
			if (onToggle) {
				onToggle(true)
			}
		}
	}

	// State for the search filter text
	const [ filterText, setFilterText ] = useState('')

	const onFilterInput = (e: Event) => {
		setFilterText((e.target as HTMLInputElement).value)
	}

	const isFiltered = (label: string) => {
		return !label.toLowerCase().includes(filterText.toLowerCase())
	}

	return <details ref={detailsRef} class="selector" aria-disabled={disabled} data-direction={direction} open={open}>
		<summary onClick={direction === 'vertical' ? onSummaryClick : undefined}>
			<p className="selector-title">{title}</p>
			<div className="selector-header">
				<p className="selector-value">
					{options.filter(({ value }) => selected.includes(value)).map(({ label }) => label).join(', ') || `Select ${title}`}
				</p>
			</div>
			{caption && <p className="selector-caption">{caption}</p>}
		</summary>
		<fieldset>
			{options.length > 5 && <input className="selector-filter" type="search" placeholder="Filter Options" onInput={onFilterInput}/>}
			<ul className="selector-options">{options.map(({ value, label, disabled, irregular, tooltip }) =>
				<SelectorOption
					key={value}
					value={value}
					label={label}
					group={group}
					multiselect={multiselect}
					hidden={isFiltered(label)}
					checked={selected.includes(value)}
					disabled={disabled}
					irregular={irregular}
					tooltip={tooltip}
					onChange={(e) => onOptionChange((e.target as HTMLInputElement).value, (e.target as HTMLInputElement).checked)}
				/>)}
			</ul>
		</fieldset>
	</details>
}

const shrink = (el: HTMLDetailsElement) => {
	// Store the current height of the element
	const startHeight = `${el.offsetHeight}px`
	// Calculate the height of the summary
	const endHeight = `${el.querySelector('summary')!.offsetHeight}px`

	// Start a WAAPI animation
	el.animate({
		// Set the keyframes from the startHeight to endHeight
		height: [ startHeight, endHeight ]
	}, {
		duration: 300
	}).onfinish = () => {
		el.removeAttribute('open')
		el.style.height = ''
	}
}

const expand = (el: HTMLDetailsElement) => {
	// Apply a fixed height on the element
	el.style.height = `${el.offsetHeight}px`
	// Force the [open] attribute on the details element
	el.setAttribute('open', '')
	// Get the current fixed height of the element
	const startHeight = `${el.offsetHeight}px`
	// Calculate the open height of the element (summary height + content height)
	const endHeight = `${el.offsetHeight + el.querySelector('summary')!.offsetHeight}px`

	// Start a WAAPI animation
	el.animate({
		// Set the keyframes from the startHeight to endHeight
		height: [ startHeight, endHeight ]
	}, {
		duration: 200
	}).onfinish = () => {
		el.style.height = ''
	}
}

export default Selector
