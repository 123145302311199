// react
import { FunctionComponent, RenderableProps, VNode, toChildArray, isValidElement } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'

// components
import Button from 'components/Button'

type TabProps = {
	id: string
	label?: string
	icon?: string
	disabled?: boolean
}

export interface TabComponent<P = {}> {
	(props: RenderableProps<P> & Readonly<TabProps>, context?: any): VNode<any> | null

	displayName?: string
	defaultProps?: Partial<P> | undefined
}

const TabPanel: FunctionComponent<{
	index?: number
	selected?: string
	onSelect?: (id: string) => void
	showPanels?: boolean
	setShowPanels?: (value: boolean) => void
}> = ({
	index,
	selected, onSelect,
	showPanels, setShowPanels,
	children
}) => {
	const tabComponents = toChildArray(children)
		.filter<VNode>((v): v is VNode => isValidElement(v) && !!(v as VNode<TabProps>).props.id) as VNode<TabProps>[]

	const [ _selected, setSelected ] = useState(selected ?? tabComponents[0]?.props.id)
	useEffect(() => {
		if (selected) {
			setSelected(selected)
		}
	}, [ selected ])

	const onSelectClick = (id: string) => {
		setSelected(id)
		onSelect && onSelect(id)
	}

	const ref = useRef<HTMLDivElement | null>(null)

	const onFullscreenClick = () => {
		if (!ref.current) {
			return
		}
		if (!document.fullscreenElement) {
			ref.current.requestFullscreen().catch(err => {
				alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`)
			})
		} else if (document.exitFullscreen) {
			document.exitFullscreen().catch(err => {
				alert(`Error attempting to disable full-screen mode: ${err.message} (${err.name})`)
			})
		}
	}

	const tabComponent = tabComponents.find(v => v.props.id === _selected)
	return <>
		{tabComponent && <div ref={ref} class="tab-panel" role="tabpanel">{tabComponent}</div>}
		<div class="tab-panel-nav">
			<Button type="icon" icon="fullscreen" onClick={onFullscreenClick}>Enter Fullscreen</Button>
			<div role="tablist" class="tab-controls">{tabComponents.map(tabComponent =>
				<button
					key={tabComponent.props.id}
					class="tab-control"
					type="button"
					role="tab"
					disabled={tabComponent.props.disabled}
					tabIndex={tabComponent.props.id === _selected ? -1 : 0}
					data-icon={tabComponent.props.icon}
					aria-controls={`tab-${tabComponent.props.id}`}
					aria-selected={tabComponent.props.id === _selected}
					onClick={() => !tabComponent.props.disabled && tabComponent.props.id !== _selected && onSelectClick(tabComponent.props.id)}>
					<span class="tab-label">{tabComponent.props.label}</span>
				</button>
			)}</div>
			{(index === (showPanels ? 1 : 0)) &&
				<Button type="icon" icon={showPanels ? 'right_panel_close' : 'right_panel_open'} onClick={() => setShowPanels && setShowPanels(!showPanels)}>
					{showPanels ? 'Hide Second Panel' : 'Show Second Panel'}
				</Button>}
		</div>
	</>
}

export default TabPanel
