// preact
import { FunctionComponent } from 'preact'
import { useState, useCallback } from 'preact/hooks'

export const useTooltip = () => {
	const [ isTooltipVisible, setIsTooltipVisible ] = useState(false)

	const showTooltip = useCallback(() => setIsTooltipVisible(true), [])
	const hideTooltip = useCallback(() => setIsTooltipVisible(false), [])

	// Return an object with the state and a function to get the bindings
	return {
		isTooltipVisible,
		getTooltipBindings: () => ({
			onMouseEnter: showTooltip,
			onMouseLeave: hideTooltip,
			onFocus: showTooltip,
			onBlur: hideTooltip,
			onKeyDown: (e: KeyboardEvent) => {
				if (e.key === 'Escape') {
					hideTooltip()
				}
			}
		})
	}
}

const Tooltip: FunctionComponent<{
	positionBlock?: 'top' | 'bottom',
	positionInline?: 'left' | 'right' | 'center',
	isVisible?: boolean
}> = ({
	positionBlock = 'top',
	positionInline = 'center',
	isVisible = false, children
}) => <div class="tooltip" role="tooltip" aria-hidden={!isVisible} data-block={positionBlock} data-inline={positionInline}>
	{children}
</div>


export default Tooltip
