// preact
import { FunctionComponent } from 'preact'

export const HeaderCell: FunctionComponent<{ icon?: string, sort?: boolean, onSort?: (ascending: boolean) => void }> = ({
	icon, sort, onSort, children
}) => {
	const onClick = () => {
		onSort && onSort(!sort)
	}

	const Component = onSort ? 'button' : 'div'
	return <th title={icon ? children?.toString() : ''} aria-sort={sort !== undefined ? sort ? 'ascending' : 'descending' : 'none'}>
		<Component className="header-cell" data-icon={icon} onClick={onClick} aria-pressed={sort !== undefined}>
			{icon ? <span className="sr-only">{children}</span> : <h3 className="header-cell-title">{children}</h3>}
			{onSort && <span className="header-cell-indicator">chevron_right</span>}
		</Component>
	</th>
}

export const BodyCell: FunctionComponent<{ numeric?: boolean }> = ({ numeric = false, children }) => {
	return <td className="body-cell">
		{numeric ? <p class="numeric">{children}</p> : children}
	</td>
}
