// preact
import { FunctionComponent } from 'preact'

// components
import Button from 'components/Button'

const AppHeader: FunctionComponent<{ onHelpClick?: () => void, onQueryClick?: () => void }> = ({ onHelpClick, onQueryClick }) => {
	return <header class="app-header">
		<img className="app-symbol" src="/images/symbol.svg" alt=""/>
		<h1 className="app-heading">
			<a className="app-title" href="https://trackingcalifornia.org/" title={'Visit the Tracking California Homepage'}>Tracking California</a>
			<p className="app-subtitle">Data Explorer</p>
		</h1>
		{onHelpClick && <Button type="icon" icon="question_mark"
		                        importance="secondary"
		                        positionBlock="bottom"
		                        tooltip="Learn how to use the Data Explorer"
		                        onClick={onHelpClick}>Instructions</Button>}
		<Button icon="edit" onClick={onQueryClick}>Modify Query</Button>
	</header>
}

export default AppHeader
