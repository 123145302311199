// preact
import { useRef, useState, useEffect, useContext } from 'preact/hooks'
import renderToHtml from 'preact-render-to-string'

// mapbox
import mapboxgl, { GeoJSONSource, GeolocateControl } from 'mapbox-gl'

mapboxgl.accessToken = 'pk.eyJ1IjoibnllbnRlayIsImEiOiJjbGtidGJ0MWYwZm45M2tvNHluNXRpOWliIn0.NVat-I3ZcD48_cb8uKsAMw'

// stores
import FiltersStore from 'stores/filters'
import MapsStore, { Type } from 'stores/maps'

// components
import Button from 'components/Button'
import Popup, { List, Row } from 'components/Popup'
import Legend, { Segment } from 'components/Legend'
import Selector from 'components/Selector'
import Download from 'components/Download'

import { TabComponent } from 'components/TabPanel'

const MapView: TabComponent<{
	topic?: string, event?: string, year?: string,
	lat?: number, lon?: number,
	north?: number, south?: number, east?: number, west?: number,
	zoom?: number, offsetX?: number, offsetY?: number,
}> = ({
	topic, event, year,
	lat = 37.16611, lon = -119.44944,
	north = 42.1, south = 31.2, east = -114.0, west = -125.0,
	zoom = 10, offsetX = 6, offsetY = 6
}) => {
	const filtersStore = useContext(FiltersStore.Context)
	const mapsStore = useContext(MapsStore.Context)

	useEffect(() => {
		if (filtersStore.area && filtersStore.querySelected) {
			mapsStore.map(filtersStore.area, filtersStore.selected, mapsStore.type)
		}
	}, [ filtersStore.area, filtersStore.querySelected, mapsStore.type ])

	const mapContainerRef = useRef<HTMLDivElement>(null)
	const mapRef = useRef<mapboxgl.Map | null>(null)
	const geoRef = useRef<GeolocateControl | null>(null)
	const svgRef = useRef<SVGSVGElement>(null)

	const [ isLoaded, setIsLoaded ] = useState(false)
	const [ colors, setColors ] = useState<Map<number, { background: string, border: string }>>(new Map())

	const fitToBounds = () => {
		if (mapRef.current) {
			mapRef.current.resize()
			mapRef.current.fitBounds([
				[ west, south ],
				[ east, north ]
			])
		}
	}

	const zoomIn = () => {
		if (mapRef.current) {
			mapRef.current.zoomIn()
		}
	}

	const zoomOut = () => {
		if (mapRef.current) {
			mapRef.current.zoomOut()
		}
	}

	const geolocate = () => {
		if (mapRef.current && geoRef.current) {
			geoRef.current.trigger()
		}
	}

	useEffect(() => {
		if (mapContainerRef.current && !mapRef.current) {
			geoRef.current = new mapboxgl.GeolocateControl({
				showUserLocation: false,
				fitBoundsOptions: { maxZoom: 10 }
			})
			const map = mapRef.current = new mapboxgl.Map({
				container: mapContainerRef.current,
				style: `mapbox://styles/nyentek/clkbsn9zg000601pw4anhfeb0`,
				center: [ lon, lat ],
				zoom,
				maxBounds: [
					[ west - offsetX, south - offsetY ],
					[ east + offsetX, north + offsetY ]
				],
				preserveDrawingBuffer: true
			})
			if (geoRef.current) {
				map.addControl(geoRef.current)
			}
			map.on('load', () => {
				map.addSource('geography', { type: 'geojson', data: { type: 'FeatureCollection', features: [] } })
				map.addLayer({
					id: 'legend',
					source: 'geography',
					type: 'fill',
					paint: {
						'fill-opacity': 0,
						'fill-outline-color': '#708090'
						//'fill-outline-color': '#db8224'
					}
				})
				map.on('sourcedata', (e) => {
					if (e.sourceId === 'geography' && e.isSourceLoaded) {
						setIsLoaded(true)
					}
				})
				map.on('click', 'legend', e => {
					const p = e.features?.[0].properties ?? {}
					const stack = p.stack ?? false
					const unit = p.unit ?? undefined
					const labels = JSON.parse(p.labels ?? '{}')
					const vs: {
						n: string, v?: number, v1?: number, v2?: number, v3?: number
					}[] = JSON.parse(p.vs ?? '[{"n":"Selected Geography"}]')
					const popup = renderToHtml(
						<Popup title={vs.map(({ n }) => n).join(', ')}>
							{vs.map(({ n, v, v1, v2, v3 }) => <List
								title={vs.length > 1 ? n : undefined}>
								{v !== undefined && <Row label={labels['value']} value={v} unit={unit}/>}
								{v1 !== undefined && <Row label={labels['value1']} value={v1} unit={stack ? unit : undefined}/>}
								{v2 !== undefined && <Row label={labels['value2']} value={v2} unit={stack ? unit : undefined}/>}
								{v3 !== undefined && <Row label={labels['value3']} value={v3} unit={stack ? unit : undefined}/>}
							</List>)}
						</Popup>
					)
					new mapboxgl.Popup()
						.setLngLat(e.lngLat)
						.setHTML(popup)
						.addTo(map)
				})
				map.on('mouseenter', 'legend', () => {
					map.getCanvas().style.cursor = 'pointer'
				})
				map.on('mouseleave', 'legend', () => {
					map.getCanvas().style.cursor = ''
				})
			})
		}

		const observer = new ResizeObserver(fitToBounds)
		if (mapContainerRef.current) {
			observer.observe(mapContainerRef.current)
		}
		return () => {
			if (mapRef.current) {
				if (geoRef.current) {
					mapRef.current.removeControl(geoRef.current)
					geoRef.current = null
				}
				mapRef.current.remove()
				mapRef.current = null
			}
			if (mapContainerRef.current) {
				observer.unobserve(mapContainerRef.current)
			}
		}
	}, [])

	useEffect(() => {
		if (mapRef.current) {
			mapRef.current.setCenter([ lon, lat ])
			mapRef.current.setZoom(zoom)
		}
	}, [ lat, lon, zoom ])

	useEffect(() => {
		if (isLoaded && mapsStore.features && mapRef.current) {
			(mapRef.current.getSource('geography') as GeoJSONSource).setData({
				type: 'FeatureCollection',
				features: mapsStore.features
			})
		}
	}, [ isLoaded && mapsStore.features ])

	const legendSteps = mapsStore.legend.steps
	const legendLastIndex = legendSteps.length - 2

	useEffect(() => {
		const legendColors = [ ...colors.entries() ]
			.filter(([ k ]) => k >= 0)
			.map(([ _, v ]) => v)

		if (isLoaded && legendColors.length && legendSteps.length && legendColors.length >= (legendSteps.length - 1) && mapRef.current) {
			const step: any[] = [
				'step',
				[ 'get', 'v', [ 'at', 0, [ 'get', 'vs' ] ] ],
				colors.get(-3)?.background ?? 'rgba(0,0,0,0.03)'
			]
			if (colors.has(-2)) {
				const { background } = colors.get(-2)!
				step.push(-2.9, background)
			}
			if (colors.has(-1)) {
				const { background } = colors.get(-1)!
				step.push(-1.9, background)
			}
			for (const [ i, s ] of legendSteps.entries()) {
				step.push(s, legendColors[i]?.background ?? legendColors[i - 1]?.background)
			}
			mapRef.current.setPaintProperty('legend', 'fill-color', step)
			mapRef.current.setPaintProperty('legend', 'fill-opacity', 1)
		}
	}, [ isLoaded, colors, legendSteps ])

	const legendUnit = mapsStore.legend.unit
	const legendNone = mapsStore.legend.none
	const legendSupp = mapsStore.legend.supp
	const legendZero = mapsStore.legend.zero

	const labels = mapsStore.labels
	const labelType = labels[mapsStore.type]
	const typeOptions = [
		{ value: 'value', label: labels.value },
		...(mapsStore.conf ? [
			labels.value3 ? { value: 'value3', label: labels.value3 } : undefined
		] : [
			labels.value1 ? { value: 'value1', label: labels.value1 } : undefined,
			labels.value2 ? { value: 'value2', label: labels.value2 } : undefined,
			labels.value3 ? { value: 'value3', label: labels.value3 } : undefined
		])
	].filter(_ => _) as { value: string, label: string }[]
	const isNum = labelType?.toLowerCase().includes('number')

	return <>
		<header className="panel-header">
			<div className="panel-header-group">
				<Selector group="measure" title="Selected Measure" direction="horizontal" options={typeOptions}
				          selected={[ mapsStore.type ]} onSelected={(selected) => mapsStore.setType(selected[0] as Type ?? 'value')}/>
			</div>
			<div className="panel-header-group">
				<Download elRef={mapRef} svgRef={svgRef} type="map" label="Download Map" topic={topic} event={event} year={year}/>
			</div>
		</header>
		{legendSteps.length ?
			<Legend ref={svgRef} title={labelType} hasNoData={legendNone} hasSuppressed={legendSupp} hasNoEvents={legendZero} topic={event}
			        setColors={setColors}>{legendSteps.map((s, i, a) =>
				<Segment>{`${s.toFixed(isNum ? 0 : 2)}—${((a[i + 1] ?? 0) - (i < legendLastIndex ? isNum ? 1 : 0.01 : 0)).toFixed(isNum ? 0 : 2)}${legendUnit}`}</Segment>
			).slice(0, -1)}</Legend> : <></>}
		<div className="map-control">
			<Button onClick={geolocate} type={'icon'} icon={'my_location'}>Zoom to Location</Button>
			<div className="map-zoom">
				<Button type={'icon'} importance={'secondary'} icon={'add'} onClick={zoomIn}>Zoom In</Button>
				<Button type={'icon'} importance={'secondary'} icon={'center_focus_strong'} onClick={fitToBounds}>Recenter Map</Button>
				<Button type={'icon'} importance={'secondary'} icon={'remove'} onClick={zoomOut}>Zoom Out</Button>
			</div>
		</div>
		{topic === 'Asthma' &&
			<div class="data-sources">
				<p class="data-sources-title">Data provided in partnership with:</p>
				<a className="data-source" href={'https://www.cdph.ca.gov/Programs/CCDPHP/DEODC/EHIB/CPE/Pages/CaliforniaBreathing.aspx'} target={'_blank'}>
					<img src={'/images/ca-breathing.webp'} alt={'California Breathing'}/>
				</a>
				<a className="data-source" href={'https://www.cdph.ca.gov/'} target={'_blank'}>
					<img src={'/images/cdph.svg'} alt={'CDPH'}/>
				</a>
			</div>
		}
		<div ref={mapContainerRef} className="map"/>
	</>
}

export default MapView
