// preact
import { useRef, useEffect } from 'preact/hooks'
import { forwardRef } from 'preact/compat'

// chart.js
import {
	Chart as _Chart, ChartType, ChartData, ChartOptions,
	LinearScale, CategoryScale, LineElement,
	Title, Legend, Tooltip
} from 'chart.js'

import {
	BarWithErrorBar, BarWithErrorBarsController,
	LineWithErrorBarsController, PointWithErrorBar
} from 'chartjs-chart-error-bars'

import Colors from './plugin.colors'

_Chart.register(
	LinearScale, CategoryScale, LineElement,
	BarWithErrorBar, BarWithErrorBarsController,
	PointWithErrorBar, LineWithErrorBarsController,
	Legend, Tooltip, Colors, Title
)

_Chart.defaults.backgroundColor = '#4972b8'
_Chart.defaults.font.family = '\'Work Sans\', sans-serif'

// component
type ChartProps = {
	type: ChartType
	data: ChartData
	xlen?: number
	ymax?: number
	id?: string
	label?: string
	width?: number
	height?: number
}

const Chart = forwardRef<HTMLCanvasElement, ChartProps>(({
	type, data, xlen, ymax,
	id, label, width, height
}, outerRef) => {
	const innerRef = useRef<HTMLCanvasElement | null>(null)
	const chartRef = useRef<_Chart | null>(null)

	useEffect(() => {
		const ctx = innerRef.current?.getContext('2d')
		if (ctx) {
			const options = type.startsWith('line') ? lineOptions(label) : barOptions(label)
			chartRef.current = new _Chart(ctx, {
				type, data, options, plugins: [ {
					id: 'backgroundColor',
					beforeDraw: (chart, args, options) => {
						const { ctx } = chart
						ctx.save()
						ctx.globalCompositeOperation = 'destination-over'
						ctx.fillStyle = options.color || '#FAFAFA'
						ctx.fillRect(0, 0, chart.width, chart.height)
						ctx.restore()
					}
				} ]
			})
		}
		return () => {
			if (chartRef.current) {
				chartRef.current.destroy()
			}
		}
	}, [ type ])

	useEffect(() => {
		if (chartRef.current) {
			chartRef.current.data = data
			if (chartRef.current.options.plugins?.title) {
				chartRef.current.options.plugins.title.text = label
			}
			const isLine = type.startsWith('line')
			if (isLine && chartRef.current.options.scales?.y) {
				chartRef.current.options.scales.y.max = ymax
			}
			if (chartRef.current.options.scales?.x?.ticks) {
				const ticks = (chartRef.current.options.scales.x as any).ticks
				if (isLine) {
					ticks.count = (xlen && xlen <= 1) ? 3 : undefined
				} else {
					ticks.font = (data.datasets?.[0]?.data as any)?.map(({ x }: { x: any }) => ({ weight: 'California' === x ? 'bold' : 'normal' }))
				}
			}
			chartRef.current.update()
		}
	}, [ type, data, xlen, ymax, label ])

	return <div className="chart">
		<canvas id={id} role="img" aria-label={label} ref={(ref) => {
			innerRef.current = ref
			if (typeof outerRef === 'function') {
				outerRef(ref)
			} else if (outerRef) {
				outerRef.current = ref
			}
		}}/>
	</div>
})

const barOptions = (label?: string) => ({
	responsive: true,
	maintainAspectRatio: false,
	interaction: {
		axis: 'x',
		mode: 'nearest',
		intersect: false
	},
	scales: {
		x: { stacked: true },
		y: { stacked: true }
	},
	plugins: {
		title: {
			display: true,
			align: 'start',
			position: 'top',
			text: label
		},
		legend: {
			display: true,
			onClick: () => {
			},
			align: 'start'
		},
		tooltip: {
			callbacks: {
				label: (context) => {
					const label = context.dataset.label ?? ''
					const y = context.parsed.y ?? null
					const yMin = Number.isFinite(context.parsed.yMin) ? context.parsed.yMin : undefined
					const yMax = Number.isFinite(context.parsed.yMax) ? context.parsed.yMax : undefined
					return `${label}: ${y}` + (yMin && yMax ? ` (${yMin}, ${yMax})` : '')
				}
			}
		}
	}
} as ChartOptions)

const lineOptions = (label?: string) => ({
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		x: {
			type: 'linear',
			ticks: {
				stepSize: 10000,
				callback: (value) => {
					value = String(value)
					const beg = value.slice(0, 4)
					// const end = value.slice(4)
					// if (end.length && end !== '0000') {
					// 	return `${beg}-${end}`
					// }
					return beg
				}
			}
		},
		y: {
			min: 0
		}
	},
	// interaction: {
	// 	axis: 'x',
	// 	mode: 'nearest',
	// 	intersect: false
	// },
	plugins: {
		title: {
			display: true,
			align: 'start',
			position: 'top',
			text: label
		},
		legend: {
			position: 'top',
			align: 'start',
			onClick: () => {
			}
		},
		tooltip: {
			callbacks: {
				title: (context) => {
					const index = context[0].dataIndex
					const data = context[0].dataset.data[index]
					const value = String((data as any)?.x ?? '0000')
					const beg = value.slice(0, 4)
					const end = value.slice(4)
					if (end.length && end !== '0000') {
						return `${beg}-${end}`
					}
					return beg
				},
				label: (context) => {
					const label = context.dataset.label ?? ''
					if (label.startsWith('ICD')) {
						return label
					}
					const y = context.parsed.y ?? null
					const yMin = Number.isFinite(context.parsed.yMin) ? context.parsed.yMin : undefined
					const yMax = Number.isFinite(context.parsed.yMax) ? context.parsed.yMax : undefined
					return `${label}: ${y}` + (yMin && yMax ? ` (${yMin}, ${yMax})` : '')
				}
			}
		}
	}
} as ChartOptions)

export default Chart
