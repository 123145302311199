import { FunctionComponent, RefObject } from 'preact'
import { useRef, useEffect } from 'preact/hooks'
import Button from 'components/Button'

interface ModalProps {
	onClose?: () => void
	title?: string
	isOpen: boolean
}

const Modal: FunctionComponent<ModalProps> = ({ title, onClose, isOpen, children }) => {
	const dialogRef: RefObject<HTMLDialogElement> = useRef<HTMLDialogElement>(null)

	useEffect(() => {
		const dialog = dialogRef.current
		if (dialog) {
			if (isOpen) {
				dialog.showModal()
			} else if (dialog.open) {
				dialog.close()
			}
		}
	}, [ isOpen ])

	const closeModal = () => {
		if (dialogRef.current && dialogRef.current.open) {
			dialogRef.current.close()
		}
		if (onClose) {
			onClose()
		}
	}

	return (
		<dialog ref={dialogRef} class={'modal'}>
			<header>
				<h3 className="heading">{title}</h3>
				<Button onClick={closeModal} type={'icon'} icon={'close'} importance={'tertiary'}>Close</Button>
			</header>
			<section className="modal-content">
				{children}
			</section>
		</dialog>
	)
}

export default Modal
