// preact
import { createContext } from 'preact'
import { useState } from 'preact/hooks'

// api
import { request } from './api'
import { Trend, Labels } from './api/types'
import { Type, Filters, BaseValues } from './types'

export type { Type }

// colors
import { getBorderColor, getBackgroundColor } from 'components/Chart/plugin.colors'

export type TrendsContext = {
	area: string | null
	conf: boolean
	type: Type
	xlen: number
	ymax: number
	data: any
	labels: Labels
	confOn: boolean
	trends: Trend[]
	clear: () => void
	setType: (type: Type) => void
	setConfOn: (on: boolean) => void
	trend (area: string, filters: Filters, type?: Type): Promise<{ area: string | null, trends: Trend[] }>
}

export default class TrendsStore {

	static Context = createContext<TrendsContext>({} as TrendsContext)

	static values ({ area, setArea, conf, setConf, type, setType }: BaseValues): TrendsContext {
		const [ xlen, setXlen ] = useState<number>(0)
		const [ ymax, setYmax ] = useState<{ mv: number, mc: number }>({ mv: 0, mc: 0 })
		const [ data, setData ] = useState<any>({ datasets: [] })
		const [ labels, setLabels ] = useState<Labels>({ name: '', value: '' })
		const [ trends, setTrends ] = useState<Trend[]>([])
		const [ confOn, setConfOn ] = useState<boolean>(true)

		return {
			area,
			conf,
			type,
			xlen,
			ymax: conf && confOn ? ymax.mc : ymax.mv,
			data,
			labels,
			confOn,
			trends,
			clear: () => {
				setArea(null)
				setConf(false)
				setType('value')
				setXlen(0)
				setYmax({ mv: 0, mc: 0 })
				setTrends([])
				setData({ datasets: [] })
				setConfOn(true)
			},
			setType,
			setConfOn,
			async trend (area, filters, type: Type | null = null) {
				try {
					const json = await request({
						source: 'explorer',
						action: 'trend',
						data: {
							area,
							filters,
							type
						}
					})

					const trends = json.data as Trend[]
					const xlen = trends.reduce((l, { data }) => l < data.length ? data.length : l, 0)
					const xmin = trends.reduce((m, { data }) => {
						const x = data.reduce((m, { x }) => x < m ? x : m, Number.MAX_SAFE_INTEGER)
						return x < m ? x : m
					}, Number.MAX_SAFE_INTEGER)
					const xmax = trends.reduce((m, { data }) => {
						const x = data.reduce((m, { x }) => x > m ? x : m, 0)
						return x > m ? x : m
					}, 0)
					setArea(json.area)
					setConf(json.conf)
					setType(json.type)
					setXlen(xlen)
					setYmax({ mv: json.ymax + (xlen <= 1 ? json.ymax * 0.1 : 0), mc: json.ymaxc + (xlen <= 1 ? json.ymaxc * 0.1 : 0) })
					setLabels(json.label)
					setTrends(trends)

					const datasets: any[] = trends.map(({ name: label, data }, i) => ({
						label, data, tension: 0.25, borderColor: getBorderColor(i), backgroundColor: getBackgroundColor(i)
					}))
					if (![ 'ncdm_air', 'ncdm_asthma', 'ncdm_bd', 'ncdm_cancer', 'ncdm_lead', 'ncdm_mih' ].includes(area) || (
						'ncdm_asthma' === area && filters['event']?.length && ![ 'ASEDI', 'ASHOI' ].includes(filters['event'][0])
					)) {
						if (xmin <= 20150000 && (20150000 <= xmax || 20142015 <= xmax)) {
							datasets.push({
								type: 'bar',
								label: 'ICD-9 to 10 Transition',
								data: [ { x: 20150000, y: json.conf && confOn ? json.ymaxc : json.ymax } ],
								borderColor: 'grey',
								backgroundColor: 'grey',
								maxBarThickness: 2
							})
						}
					}
					setData({ datasets })

					return { area, trends }
				} catch (_) {
					return { area: null, trends: [] }
				}
			}
		}
	}
}
