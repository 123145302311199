if ((module as any).hot) {
	(module as any).hot.accept()
}

if (process.env.NODE_ENV === 'development') {
	require('preact/debug')
}

// preact
import { render, FunctionComponent } from 'preact'
import { useState } from 'preact/hooks'

// stores
import AreasStore from 'stores/areas'
import FiltersStore from 'stores/filters'
import MapsStore from 'stores/maps'
import GraphsStore from 'stores/graphs'
import TrendsStore from 'stores/trends'
import TablesStore from 'stores/tables'

// components
//import ErrorBoundary from 'components/ErrorBoundary'

// types
import { Type } from 'stores/types'

import App from './App'

const Index: FunctionComponent = () => {
	const [ area, setArea ] = useState<string | null>(null)
	const [ conf, setConf ] = useState<boolean>(false)
	const [ type, setType ] = useState<Type>('value')
	const values = { area, setArea, conf, setConf, type, setType }

	return <AreasStore.Context.Provider value={AreasStore.values()}>
		<FiltersStore.Context.Provider value={FiltersStore.values()}>
			<MapsStore.Context.Provider value={MapsStore.values(values)}>
				<GraphsStore.Context.Provider value={GraphsStore.values(values)}>
					<TrendsStore.Context.Provider value={TrendsStore.values(values)}>
						<TablesStore.Context.Provider value={TablesStore.values()}>
							<App/>
						</TablesStore.Context.Provider>
					</TrendsStore.Context.Provider>
				</GraphsStore.Context.Provider>
			</MapsStore.Context.Provider>
		</FiltersStore.Context.Provider>
	</AreasStore.Context.Provider>
}

render(
	<Index/>,
	document.getElementById('root') as HTMLElement
)
