// preact
import { useContext } from 'preact/hooks'
import { forwardRef } from 'preact/compat'

// stores
import AreasStore from 'stores/areas'
import FiltersStore from 'stores/filters'

// components
import Button from 'components/Button'
import Overlay from 'components/Overlay'
import Selector, { SelectorProps } from 'components/Selector'

type Props = { showQuery: boolean, close: () => void }

const Query = forwardRef<HTMLElement, Props>(({ showQuery, close }, ref) => {
	const areasStore = useContext(AreasStore.Context)
	const filtersStore = useContext(FiltersStore.Context)

	const selectorAreas: SelectorProps = {
		group: 'area',
		title: 'Topic',
		options: areasStore.areas.map(({ name, label, tooltip }) => ({ value: name, label: label ?? name, tooltip: tooltip ?? undefined })),
		selected: filtersStore.area ? [ filtersStore.area ] : [],
		onSelected: (selected) => {
			if (selected.length) {
				const area = areasStore.areas.find(area => area.name === selected[0])
				if (area) {
					filtersStore.setArea(area)
				}
			} else {
				filtersStore.clear()
			}
		},
		onToggle: (open) => {
			if (open) {
				areasStore.list()
			}
		},
		multiselect: false,
		caption: !filtersStore.area ? 'Select a content area to get started' : undefined
	}

	const selectorFilters: SelectorProps[] = filtersStore.selectors.filter(s => ![ 'co', 'ct', 'zc' ].includes(s.group))
		.map(({ group, title, options, selected }) => ({
			group,
			title,
			options,
			selected,
			onSelected: (selected) => onFilterSelected(group, selected,
				options.find(({ value: v }) => v === selected[0])?.irregular ?? false
			),
			onToggle: (open) => onFilterToggle(group, open),
			multiselect: false,
			caption: group === 'event' ? 'Selecting disabled values may reset following selections' : 'Disabled values require changing other selections first'
		}))
	const selectorGeographies: SelectorProps[] = []
	if (filtersStore.area) {
		const geoOptions = filtersStore.geoSelectors.map(({ group, title, irregular }) => ({ value: group, label: title, irregular }))
		selectorGeographies.push({
			group: 'geography',
			title: 'Geography',
			options: geoOptions,
			selected: filtersStore.geoSelected,
			onSelected: (selected) => onFilterSelected('geography', selected,
				geoOptions.find(({ value: v }) => v === selected[0])?.irregular ?? false
			),
			onToggle: (open) => onFilterToggle('geography', open),
			multiselect: false
		})
		const geoSelector = filtersStore.geoSelectors.find(({ group }) => group === filtersStore.geoSelected[0])
		if (geoSelector) {
			selectorGeographies.push({
				group: geoSelector.group,
				title: geoSelector.title,
				options: geoSelector.options,
				selected: geoSelector.selected,
				onSelected: (selected) => {
					const irregular = selected.find(s => geoSelector.options.find(({ value: v }) => v === s)?.irregular)
					onFilterSelected(geoSelector.group, irregular ? [ irregular ] : selected, !!irregular)
				},
				onToggle: (open) => {
					if (open) {
						const newFilters = { ...filtersStore.filters }
						const attribute = filtersStore.geoSelected[0]
						newFilters[attribute] = filtersStore.selected[attribute] ?? []
						filtersStore.filter(newFilters, false, true)
					}
				},
				multiselect: true,
				caption: `Only ${geoSelector.title} with data are shown`
			})
		}
	}
	const selectors = [ selectorAreas, ...selectorFilters, ...selectorGeographies ]

	const onFilterToggle = (attribute: string, open: boolean) => {
		const newFilters = { ...filtersStore.filters }
		if (open) {
			if (attribute === 'geography') {
				delete newFilters['co']
				delete newFilters['ct']
				delete newFilters['zc']
			} else {
				delete newFilters[attribute]
			}
			filtersStore.filter(newFilters)
		} else {
			if (attribute === 'geography') {
				attribute = filtersStore.geoSelected[0]
			}
			newFilters[attribute] = filtersStore.selected[attribute] ?? []
			filtersStore.setFilters(newFilters)
		}
	}

	const onFilterSelected = (attribute: string, values: string[], irregular: boolean) => {
		const newFilters = { ...(!irregular ? filtersStore.filters : {}) }
		if (attribute === 'geography') {
			delete newFilters['co']
			delete newFilters['ct']
			delete newFilters['zc']
			if (values.length) {
				attribute = values[0]
				values = []
			}
		}
		newFilters[attribute] = values
		delete newFilters['geography']
		filtersStore.filter(newFilters, true)
	}

	return <Overlay ref={ref} id="query" show={showQuery} close={close} position="left" title={'Query Builder'}>
		<div class="query-actions">
			<Button icon="delete_history" to={'/'} importance={'secondary'}>Reset Query</Button>
		</div>
		<form action="" className="query-params">
			{selectors.map((selector, index) =>
				<Selector
					key={index > 0 ? `${filtersStore.area ?? ''}-${selector.group}` : selector.group}
					{...selector}
					open={!onFilterSelected && index === 0}
				/>
			)}
		</form>
	</Overlay>
})

export default Query
