import { FunctionComponent } from 'preact'

export const Row: FunctionComponent<{
	label?: string, value: number, unit?: string
}> = ({
	label, value, unit = ''
}) => {
	return <div className="popup-row">
		<dt>{label}</dt>
		<dd>{value >= 0 ? `${value.toLocaleString('en-US')}${unit}` : value >= -1 ? 'No events' : value >= -2 ? 'Data suppressed' : 'No data'}</dd>
	</div>
}

export const List: FunctionComponent<{ title?: string }> = ({ title, children }) => {
	return <li className="popup-list">
		{title && <header class="popup-list-title">{title}</header>}
		<dl>{children}</dl>
	</li>
}

const Popup: FunctionComponent<{ title?: string }> = ({ title, children }) => {
	return <>
		<header className="popup-header">
			{title && <p class="popup-title">{title}</p>}
		</header>
		<ul className="popup-lists">{children}</ul>
	</>
}

export default Popup
