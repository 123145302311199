// preact
import { FunctionComponent } from 'preact'

// components
import Toggle, { Option } from 'components/Toggle'

const ConfidenceToggle: FunctionComponent<{
	group: string, show: boolean, onChange: (show: boolean) => void
}> = ({
	group, show = false, onChange
}) => {
	return <Toggle group={group}
	               title="Confidence Intervals:"
	               selected={`toggle-confidence-${show ? 'on' : 'off'}`}
	               onSelect={(selected: string) => onChange(selected === `toggle-confidence-on`)}>
		<Option value={`toggle-confidence-off`} icon={'visibility_off'} iconOnly={true} tooltip={'Hide 95% Confidence Intervals'}>Hide</Option>
		<Option value={`toggle-confidence-on`} icon={'visibility'} iconOnly={true} tooltip={'Show 95% Confidence Intervals'}>Show</Option>
	</Toggle>
}

export default ConfidenceToggle
