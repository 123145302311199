// preact
import { FunctionComponent, VNode, toChildArray, isValidElement, cloneElement } from 'preact'
import { useRef, useEffect } from 'preact/hooks'


const ResizablePanels: FunctionComponent<{
	minWidth?: number
	showPanels?: boolean
}> = ({
	minWidth = 256,
	showPanels,
	children
}) => {
	const panelRefs = useRef<Map<number, HTMLDivElement & { dragValue: number }>>(new Map())
	const resizerRefs = useRef<Map<number, HTMLDivElement & { dragHandle?: (e: MouseEvent) => any }>>(new Map())

	useEffect(() => {
		if (!showPanels) {
			return
		}
		const dragHandler = (index: number, resizer: HTMLDivElement) => (event: MouseEvent) => {
			event.preventDefault()

			const rect = resizer.parentElement?.getBoundingClientRect()
			const panelNow = panelRefs.current.get(index)
			const panelNext = panelRefs.current.get(index + 1)
			if (!(rect && panelNow && panelNext)) {
				return
			}
			const minPixelWidth = minWidth / rect.width * 100

			const delta = (((event.clientX - rect.left) / rect.width) * 100) - panelNow.dragValue

			// Make sure panels don't get too small
			if (panelNow.dragValue + delta < minPixelWidth || panelNext.dragValue - delta < minPixelWidth) {
				return
			}

			panelNow.dragValue += delta
			panelNext.dragValue -= delta
			resizer.ariaValueNow = panelNow.dragValue.toFixed(0)
			resizer.ariaValueText = `Left panel size: ${panelNow.dragValue.toFixed(0)}%; Right panel size: ${panelNext.dragValue.toFixed(0)}%`

			requestAnimationFrame(() => {
				panelNow.style.flexBasis = `${panelNow.dragValue.toFixed(2)}%`
				panelNext.style.flexBasis = `${panelNext.dragValue.toFixed(2)}%`
			})
		}

		for (const [ index, resizer ] of resizerRefs.current.entries()) {
			resizer.addEventListener('drag', resizer.dragHandle = dragHandler(index, resizer))
		}
		return () => {
			for (const resizer of resizerRefs.current.values()) {
				if (resizer.dragHandle) {
					resizer.removeEventListener('drag', resizer.dragHandle)
				}
			}
		}
	}, [ showPanels ])

	const components = toChildArray(children)
		.filter<VNode>((v): v is VNode => isValidElement(v))
		.map((v, index) => cloneElement(v, { index }))

	return <div className="resizable-panels">
		{components.filter((_, i) => showPanels || i === 0).map((component, index) => <>
			<div ref={e => e ? ((e as { dragValue?: number }).dragValue = 50, panelRefs.current.set(index, e as HTMLDivElement & {
				dragValue: number
			})) : panelRefs.current.delete(index)}
			     style={{ flexBasis: '50%' }}
			     className="panel"
			     data-index={index}>
				{component}
			</div>
			{(index < components.length - 1) && <div
				ref={e => e ? resizerRefs.current.set(index, e) : resizerRefs.current.delete(index)}
				role="slider"
				className="resizer"
				draggable={true}
				onDragOver={e => e.preventDefault()}
				aria-valuemin={0}
				aria-valuemax={100}
				aria-valuenow={50}
				aria-valuetext="Left panel size: 50%; Right panel size: 50%"
				aria-label="Resize panels">
			</div>}
		</>)}
	</div>
}

export default ResizablePanels
