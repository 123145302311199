// preact
import { FunctionComponent } from 'preact'
import { useState } from 'preact/hooks'

// components
import Modal from 'components/Modal'
import Button from 'components/Button'
import Item from './Item'

const Instructions: FunctionComponent<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
	const [ activeItem, setActiveItem ] = useState<number | null>(0)

	const items = [
		{
			x: 12.5, y: 12, label: 'Topic Selection', content: (<>
				<p>Select a topic for which you would like to explore data, and then select the subtopic and measure to build a query.</p>
				<p>Each topic has a default event and measure that is displayed. The data will automatically update as you change the selections.</p>
			</>)
		},
		{ x: 12.5, y: 50, label: 'Query Building', content: 'Adjust the year, geography, and demographics of the data' },
		{ x: 29, y: 8, label: 'Value Display', content: 'Change which value is displayed for the selected measure' },
		{ x: 79, y: 4, label: 'About the Data', content: 'Learn more details about the topic and data sources' },
		{
			x: 96,
			y: 8,
			label: 'Data Export',
			content: 'Download the map, chart, or time trend as an image file or export the data table to view in external applications'
		},

		{ x: 27, y: 86, label: 'Expand View', content: 'Expand the view to see the visualization in Fullscreen' },
		{ x: 63, y: 86, label: 'Controls', content: 'Change view options and see different data visualizations—map, chart, time trend, and table' },
		{ x: 96, y: 86, label: 'Controls', content: 'Divide the view to show side-by-side visualizations ' },

		{ x: 20, y: 0, label: 'Help', content: 'Return to this tutorial' }
	]

	const showNext = (index: number) => index < items.length - 1
	const showBack = (index: number) => index > 0

	const handleItemClick = (index: number) => {
		setActiveItem(index === activeItem ? null : index)
	}

	const handleNext = (currentIndex: number) => {
		if (activeItem === items.length - 1) {
			setActiveItem(0)
		} else {
			setActiveItem(currentIndex + 1)
		}
	}

	const handleBack = (currentIndex: number) => {
		setActiveItem(currentIndex - 1)
	}

	const path = '/images/instructions'
	const url = 'https://trackingca.netlify.app'

	return <Modal title="Welcome to Tracking California's Data Explorer"
	              isOpen={isOpen} onClose={onClose}>
		<p className="copy">The Data Explorer is best viewed in landscape orientation on a display with resolution of at least 1440 × 900px.</p>
		<br/>
		<figure className="instructions-graphic">
			<img srcset={`${path}@1x.webp 1x, ${path}@2x.webp 2x`} src={path + '@1x.png'} alt=""/>
			<figcaption>
				<ol class="instructions-items">{items.map((item, index) => <Item
					key={index}
					x={item.x} y={item.y} label={item.label}
					isVisible={activeItem === index}
					showNext={showNext(index)}
					showBack={showBack(index)}
					onNext={() => handleNext(index)}
					onBack={() => handleBack(index)}
					onClick={() => handleItemClick(index)}
					onClose={onClose}>
					{item.content}
				</Item>)}</ol>
			</figcaption>
		</figure>
		<div className="button-row">
			<Button importance="secondary" to={url + '/images/uploads/data-explorer-user-guide.pdf'} icon={'description'}>View User Guide</Button>
			<Button onClick={onClose} icon={'arrow_forward'}>Explore the Data</Button>
		</div>
	</Modal>
}

export default Instructions
