// preact
import { createContext } from 'preact'
import { useState } from 'preact/hooks'

// api
import { request } from './api'
import { Area, Attribute } from './api/types'
import { Filters, Selectors } from './types'

export { Filters }

export type FiltersContext = {
	init: boolean
	area: string | null
	filters: Filters
	selected: Filters
	selectors: Selectors
	geoSelectors: Selectors
	geoSelected: string[]
	querySelected: string
	clear (): void
	setArea (area: Area, init?: boolean): { area: string, filters: Filters, selectors: Selectors }
	setFilters (filters: Filters): Filters
	filter (filters: Filters, replace?: boolean, geoids?: boolean): Promise<{ area: string | null, filters: Filters, selectors: Selectors }>
}

const toFilters = (attributes: Attribute[]): Filters => attributes.reduce<{ [p: string]: string[] }>((o, {
	name: n,
	selected: s
}) => (s ? o[n] = s : o, o), {})

const toSelectors = (attributes: Attribute[]): Selectors => attributes.map(({ name, label, values, selected }) => ({
	group: name,
	title: label ?? name,
	options: values.map(({ name, label, tooltip }) => ({
		value: name, label: label ?? name, disabled: false, irregular: true, tooltip: tooltip ?? undefined, tooltipOg: tooltip ?? undefined
	})),
	selected: selected ?? []
}))

export default class FiltersStore {

	static Context = createContext<FiltersContext>({} as FiltersContext)

	static values (): FiltersContext {
		const [ init, setInit ] = useState<boolean>(false)
		const [ area, setArea ] = useState<string | null>(null)
		const [ filters, setFilters ] = useState<Filters>({})
		const [ selected, setSelected ] = useState<Filters>({})
		const [ selectors, setSelectors ] = useState<Selectors>([])

		return {
			init,
			area,
			filters,
			selected,
			selectors: selectors.filter(({ group }) => ![ 'co', 'ct', 'zc' ].includes(group)),
			geoSelectors: selectors.filter(({ group }) => [ 'co', 'ct', 'zc' ].includes(group)),
			geoSelected: [ Object.keys(selected).find((key) => [ 'co', 'ct', 'zc' ].includes(key)) ?? 'co' ],
			querySelected: Object.entries(selected).map(([ k, v ]) => k + '=' + v.join(',')).join('&'),
			clear () {
				setArea(null)
				setFilters({})
				setSelected({})
				setSelectors([])
			},
			setArea (area, init = false) {
				const filters = {
					...toFilters(area.filters.filter(({ name }) => ![ 'co', 'ct', 'zc' ].includes(name))),
					...toFilters([ area.filters.filter(({ name }) => [ 'co', 'ct', 'zc' ].includes(name))
						.sort(({ keyed }) => keyed ? -1 : 0)[0]
					])
				}
				const selectors = toSelectors(area.filters)
				setArea(area.name)
				setFilters(filters)
				setSelected(filters)
				setSelectors(selectors)
				setInit(init)
				return { area: area.name, filters, selectors }
			},
			setFilters (filters) {
				setFilters(filters)
				return filters
			},
			async filter (filters, replace = false, geoids = false) {
				try {
					const json = await request({
						source: 'explorer',
						action: 'filter',
						data: {
							area,
							filters,
							geoids
						}
					})

					const attributes: Attribute[] = json.filters
					const event = attributes.find(({ name }) => name === 'event')?.label ??
						selectors.find(({ group }) => group === 'event')?.title
					const newSelected: Filters = {}
					const newSelectors: Selectors = selectors.map(({ group, title, options }) => {
						const attribute = attributes.find(({ name }) => name === group)
						title = attribute?.label ?? title
						const name = attribute?.name ?? ''
						const values = attribute?.values ?? []
						const isGeo = [ 'co', 'ct', 'zc' ].includes(group)
						const isAttrGeo = [ 'co', 'ct', 'zc' ].includes(name)

						const s = isAttrGeo && Array.isArray(filters[name]) ? filters[name] : selected[name] ?? []
						const s1 = values.length || geoids || !isAttrGeo ? values.filter(({ name: v }) => s.includes(v)).map(({ name: n }) => n) : s
						const _selected = s1.length ? s1 : attribute?.selected ?? []
						if (!isGeo) {
							newSelected[group] = _selected
						}
						return {
							group,
							title,
							options: geoids && isAttrGeo && !filters[name]?.length ?
								values.map(({ name, label, tooltip }) => ({
									value: name, label: label ?? name, disabled: false, irregular: false,
									tooltip: tooltip ?? undefined, tooltipOg: tooltip ?? undefined
								})) :
								options.map(({ value, label, tooltipOg }, index) => {
									const attrValue = values.find(({ name }) => name === value)
									const irregular = !isAttrGeo ? !attrValue : false
									const disabled = name !== 'event' && irregular
									const tooltip = tooltipOg = attrValue?.tooltip ?? tooltipOg
									return {
										value, label,
										disabled,
										irregular,
										tooltip: tooltip ? tooltip : disabled ? `Unavailable for current selections or ${event}` : undefined,
										tooltipOg
									}
								}),
							selected: _selected,
							irregular: isGeo ? !isAttrGeo : undefined
						}
					})
					const geoAttribute = attributes.filter(({ name }) => [ 'co', 'ct', 'zc' ].includes(name))
						.sort(({ keyed }) => keyed ? -1 : 0)[0]
					if (geoAttribute) {
						const name = geoAttribute.name
						const values = geoAttribute.values
						const s = Array.isArray(filters[name]) ? filters[name] : selected[name] ?? []
						newSelected[name] = values.length || geoids ? values.filter(({ name: v }) => s.includes(v)).map(({ name: n }) => n) : s
					}

					setArea(json.area)
					setFilters(replace ? newSelected : filters)
					setSelected(newSelected)
					setSelectors(newSelectors)
					setInit(false)

					return { area: json.area, filters, selectors: newSelectors }
				} catch (_) {
					return { area: null, filters: {}, selectors: [] }
				}
			}
		}
	}
}
