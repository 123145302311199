// preact
import { useContext, useEffect, useRef } from 'preact/hooks'

// stores
import FiltersStore from 'stores/filters'
import GraphsStore, { Type, Sort } from 'stores/graphs'

// components
import Empty from 'components/Empty'
import Chart from 'components/Chart'
import Selector from 'components/Selector'
import Download from 'components/Download'
import ConfidenceToggle from 'components/ConfidenceToggle'

import { TabComponent } from 'components/TabPanel'

const SORT_OPTIONS = [
	{ value: 'asc', label: 'Ascending' },
	{ value: 'dsc', label: 'Descending' },
	{ value: 'abc', label: 'Alphabetical' }
] as { value: Sort, label: string }[]

const GraphView: TabComponent<{ topic?: string, event?: string, year?: string }> = ({ topic, event, year }) => {
	const filtersStore = useContext(FiltersStore.Context)
	const graphsStore = useContext(GraphsStore.Context)

	useEffect(() => {
		if (filtersStore.area && filtersStore.querySelected) {
			graphsStore.graph(filtersStore.area, filtersStore.selected, graphsStore.type, graphsStore.sort)
		}
	}, [ filtersStore.area, filtersStore.querySelected, graphsStore.type, graphsStore.sort ])

	const chartRef = useRef<HTMLCanvasElement>(null)
	const populated = graphsStore.graphs.length
	const isValue = 'value' === graphsStore.type
	const labels = graphsStore.labels
	const typeOptions = [
		{ value: 'value', label: labels.value },
		...(graphsStore.conf ? [
			labels.value3 ? { value: 'value3', label: labels.value3 } : undefined
		] : [
			labels.value1 ? { value: 'value1', label: labels.value1 } : undefined,
			labels.value2 ? { value: 'value2', label: labels.value2 } : undefined,
			labels.value3 ? { value: 'value3', label: labels.value3 } : undefined
		])
	].filter(_ => _) as { value: string, label: string }[]
	return <>
		<header className="panel-header">
			<div className="panel-header-group">
				<Selector group="measure" title="Selected Measure" direction="horizontal" options={typeOptions}
				          selected={[ graphsStore.type ]} onSelected={(selected) => graphsStore.setType(selected[0] as Type ?? 'value')}/>
				<Selector group="sort" title="Sort By" direction="horizontal" options={SORT_OPTIONS} disabled={!populated}
				          selected={[ graphsStore.sort ]} onSelected={(selected) => graphsStore.setSort(selected[0] as Sort ?? 'asc')}/>
				{isValue && graphsStore.conf && <ConfidenceToggle group="toggle-graph" show={graphsStore.confOn} onChange={graphsStore.setConfOn}/>}
			</div>
			<Download elRef={chartRef} type="chart" label="Download Chart" topic={topic} event={event} year={year} disabled={!populated}/>
		</header>
		{populated ? <Chart ref={chartRef} type={isValue && graphsStore.conf && graphsStore.confOn ? 'barWithErrorBars' : 'bar'}
		                    data={graphsStore.data} label={[ event, year ].join(', ')}/> : <Empty>Create a Query to View Chart Data</Empty>}
	</>
}

export default GraphView
