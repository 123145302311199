// preact
import { ComponentChildren } from 'preact'
import { forwardRef } from 'preact/compat'

// components
import Button from 'components/Button'

type OverlayProps = {
	id?: string
	show: boolean
	close: () => void
	title?: string
	position?: 'left' | 'right'
	children?: ComponentChildren
}

const Overlay = forwardRef<HTMLElement, OverlayProps>(({
	id, show, close, title, position, children
}, ref) => {
	return <section ref={ref}
	                id={id}
	                aria-hidden={!show}
	                data-position={position}
	                class="overlay">
		{title && <header className="overlay-header">
			<h3 className="overlay-title">{title}</h3>
			<Button type="icon" icon="close" importance="tertiary" disabled={!show} onClick={close}>Close</Button>
		</header>}
		{children}
	</section>
})

export default Overlay
