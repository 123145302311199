import { FunctionComponent } from 'preact'
import Tooltip, { useTooltip } from 'components/Tooltip'

const Button: FunctionComponent<{
	// config
	type?: 'text' | 'icon' | 'action'
	importance?: 'primary' | 'secondary' | 'tertiary'
	quality?: 'good' | 'bad' | 'neutral'
	icon?: string
	side?: 'start' | 'end'
	presentational?: boolean
	current?: 'page' | 'step' | 'location' | 'date' | 'time' | 'true' | 'false'
	role?: 'tab' | 'button'
	// link
	to?: string
	// form
	form?: string
	submit?: boolean
	disabled?: boolean
	// event
	onClick?: (e: MouseEvent) => void
	id?: string
	tooltip?: any
	positionBlock?: 'top' | 'bottom'
	positionInline?: 'left' | 'right' | 'center'
}> = ({

	type = 'text',
	importance = 'primary',
	quality = 'neutral',
	icon,
	side = 'end',
	presentational,
	to,
	form,
	submit = false,
	disabled = false,
	onClick,
	id,
	current = 'false',
	role = 'button',
	tooltip,
	positionBlock = 'top',
	positionInline = 'center',
	children
}) => {
	const Component = presentational ? 'div' : to ? 'a' : 'button'

	const { isTooltipVisible, getTooltipBindings } = useTooltip()
	const tooltipBindings = tooltip ? getTooltipBindings() : {}

	return <Component
		className="button"
		id={id}
		data-type={type}
		data-importance={importance}
		data-quality={quality}
		data-icon={icon}
		data-icon-position={icon && side}
		href={to}
		form={form}
		role={role}
		target={to?.startsWith('http') ? '_blank' : undefined}
		type={submit ? 'submit' : 'button'}
		disabled={disabled}
		tabIndex={disabled ? -1 : 0}
		aria-current={current}
		aria-describedby={tooltip ? `${id}-tooltip` : undefined}
		onClick={onClick}
		title={type === 'icon' ? children?.toString() : undefined}
		{...tooltipBindings}>
		<span className="button-label">{children}</span>
		{tooltip && <Tooltip children={tooltip} isVisible={isTooltipVisible} positionBlock={positionBlock} positionInline={positionInline}/>}
	</Component>
}

export default Button
