// preact
import { forwardRef } from 'preact/compat'

// components
import Overlay from 'components/Overlay'

type AboutProps = {
	src: string
	show: boolean
	close: () => void
}

const About = forwardRef<HTMLElement, AboutProps>(({ src, show, close }, ref) => {
	return <Overlay ref={ref}
	                id="about"
	                show={show}
	                close={close}
	                title="About these data"
	                position="right">
		<iframe className="about-content" src={src} loading={'lazy'}/>
	</Overlay>
})

export default About
