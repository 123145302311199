// preact
import { createContext } from 'preact'
import { useState } from 'preact/hooks'

// api
import { request } from './api'
import { Graph, Labels } from './api/types'
import { Type, Filters, BaseValues } from './types'

export type { Type }

export type Sort = 'abc' | 'asc' | 'dsc'

export type GraphsContext = {
	area: string | null
	conf: boolean
	type: Type
	sort: Sort
	data: any
	labels: Labels
	confOn: boolean
	graphs: Graph[]
	clear: () => void
	setType: (type: Type) => void
	setSort: (sort: Sort) => void
	setConfOn: (on: boolean) => void
	graph (area: string, filters: Filters, type?: Type, sort?: Sort): Promise<{ area: string | null, graphs: Graph[] }>
}

export default class GraphsStore {

	static Context = createContext<GraphsContext>({} as GraphsContext)

	static values ({ area, setArea, conf, setConf, type, setType }: BaseValues): GraphsContext {
		const [ sort, setSort ] = useState<Sort>('asc')
		const [ data, setData ] = useState<any>({ datasets: [ {} ] })
		const [ labels, setLabels ] = useState<Labels>({ name: '', value: '' })
		const [ graphs, setGraphs ] = useState<Graph[]>([])
		const [ confOn, setConfOn ] = useState<boolean>(true)

		return {
			area,
			conf,
			type,
			sort,
			data,
			labels,
			confOn,
			graphs,
			clear: () => {
				setArea(null)
				setConf(false)
				setType('value')
				setSort('asc')
				setGraphs([])
				setData({ datasets: [] })
				setConfOn(true)
			},
			setType,
			setSort,
			setConfOn,
			async graph (area, filters, type: Type | null = null, sort: Sort | null = null) {
				try {
					const json = await request({
						source: 'explorer',
						action: 'graph',
						data: {
							area,
							filters,
							type,
							sort
						}
					})
					const graphs = json.data as Graph[]
					const labels = json.label as Labels
					const value = json.type as Type
					setArea(json.area)
					setConf(json.conf)
					setType(json.type)
					setSort(json.sort)
					setLabels(labels)
					setGraphs(json.data)

					const datasets = [ {
						label: labels[value],
						backgroundColor: graphs.map<string>(({ name }) => name === 'California' ? 'rgba(255, 183, 66, 0.75)' : 'rgba(73, 114, 184, 0.67)'),
						data: graphs.map(({ name: x, value: y, values: v }) => ({
							x, y,
							...(((v ?? {}) as { yMin?: number }).yMin !== undefined ? v : {})
						})),
						tension: 0.25
					} ]
					if (graphs[0].values) {
						const values = graphs[0].values as { v1?: number, v2?: number, v3?: number }
						const vals: ('v1' | 'v2' | 'v3')[] = []
						if (values.v1 !== undefined) {
							vals.push('v1')
						}
						if (values.v2 !== undefined) {
							vals.push('v2')
						}
						if (values.v3 !== undefined) {
							vals.push('v3')
						}
						if (vals.length) {
							datasets[0].backgroundColor = graphs.map<string>(({ name }) => 'rgba(168, 202, 229, 0.67)')
						}
						for (const val of vals) {
							datasets.push({
								label: labels[val === 'v1' ? 'value1' === value ? 'value' : 'value1' :
									val === 'v2' ? 'value2' === value ? 'value' : 'value2' :
										'value3' === value ? 'value' : 'value3'] ?? '',
								backgroundColor: graphs.map(({ name }) => {
									switch (val) {
										case 'v1':
											return 'rgba(107, 159, 88, 0.67)'
										case 'v2':
											return 'rgba(109, 147, 186, 0.67)'
										case 'v3':
											return 'rgba(229, 146, 67, 0.67)'
									}
								}),
								data: graphs.map(({ name: x, values: v }) => ({
									x, y: ((v ?? {}) as { v1?: number, v2?: number, v3?: number })[val] ?? 0
								})),
								tension: 0.25
							})
						}
					}

					setData({ datasets })
					return { area, graphs }
				} catch (_) {
					return { area: null, graphs: [] }
				}
			}
		}
	}
}
