import type { Chart, ChartDataset } from 'chart.js/dist/types';

export interface ColorsPluginOptions {
	enabled?: boolean;
	forceOverride?: boolean;
}

interface ColorsDescriptor {
	backgroundColor?: string | string[] | any;
	borderColor?: string | string[] | any;
}

const BORDER_COLORS = [
	'rgb(255, 183, 66)',
	'rgb(73, 114, 184)',
	'rgb(153, 184, 73)',
	'rgb(184, 89, 73)',
	'rgb(80, 99, 133)',
	'rgb(108, 120, 78)',
	'rgb(120, 84, 78)',
	'rgb(62, 69, 82)',
	'rgb(55, 56, 50)',
	'rgb(66, 29, 23)'
];

// Border colors with 50% transparency
const BACKGROUND_COLORS = /* #__PURE__ */ BORDER_COLORS.map((color) =>
	color.replace('rgb(', 'rgba(').replace(')', ', 0.67)')
);

export function getBorderColor(i: number) {
	return BORDER_COLORS[i % BORDER_COLORS.length];
}

export function getBackgroundColor(i: number) {
	return BACKGROUND_COLORS[i % BACKGROUND_COLORS.length];
}

function colorizeDefaultDataset(dataset: ChartDataset, i: number) {
	dataset.borderColor = getBorderColor(i);
	dataset.backgroundColor = getBackgroundColor(i);

	return ++i;
}

function getColorizer(chart: Chart) {
	let i = 0;

	return (dataset: ChartDataset, datasetIndex: number) => {
		const meta = chart.getDatasetMeta(datasetIndex);
		const controller = meta && meta.controller;

		if (controller) {
			i = colorizeDefaultDataset(dataset, i);
		}
	};
}

function containsColorsDefinitions(
	descriptors: (ColorsDescriptor[] | Record<string, ColorsDescriptor>)
): descriptors is ColorsDescriptor[] {
	let k: keyof typeof descriptors;

	for (k in descriptors) {
		if (
			Object.prototype.hasOwnProperty.call(descriptors, k) &&
			(descriptors[k] as ColorsDescriptor).borderColor ||
			(descriptors[k] as ColorsDescriptor).backgroundColor
		) {
			return true;
		}
	}

	return false;
}

function containsColorsDefinition(
	descriptor: ColorsDescriptor | undefined
): descriptor is ColorsDescriptor {
	return (
		descriptor !== undefined &&
		(descriptor.borderColor || descriptor.backgroundColor)
	);
}

export default {
	id: 'colors',

	defaults: {
		enabled: true,
		forceOverride: false,
	} as ColorsPluginOptions,

	beforeLayout(chart: Chart, _args: any[], options: ColorsPluginOptions) {
		if (!options.enabled) {
			return;
		}

		const {
			data: { datasets },
			options: chartOptions,
		} = chart.config;
		const elements = chartOptions?.elements;

		if (
			!options.forceOverride &&
			(containsColorsDefinitions(datasets) ||
				containsColorsDefinition(chartOptions) ||
				(elements && containsColorsDefinitions(elements)))
		) {
			return;
		}

		const colorizer = getColorizer(chart);

		datasets.forEach(colorizer);
	},
};
