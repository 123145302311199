// preact
import { createContext } from 'preact'
import { useState } from 'preact/hooks'

// api
import { request } from './api'
import { Labels } from './api/types'
import { Type, Filters, BaseValues } from './types'

export type { Type }

type Legend = { unit: string, steps: number[], none?: boolean, supp?: boolean, zero?: boolean }

export type MapsContext = {
	area: string | null
	conf: boolean
	type: Type
	labels: Labels
	legend: Legend
	features: any[]
	setType: (type: Type) => void
	map (area: string, filters: Filters, type?: Type): Promise<{ area: string | null, features: any[] }>
}

export default class MapsStore {

	static Context = createContext<MapsContext>({} as MapsContext)

	static values ({ area, setArea, conf, setConf, type, setType }: BaseValues): MapsContext {
		const [ labels, setLabels ] = useState<Labels>({ name: '', value: '' })
		const [ legend, setLegend ] = useState<Legend>({ unit: '', steps: [] })
		const [ features, setFeatures ] = useState<any[]>([])

		return {
			area,
			conf,
			type,
			labels,
			legend,
			features,
			setType,
			async map (area, filters, type: Type | null = null) {
				try {
					const json = await request({
						source: 'explorer',
						action: 'map',
						data: {
							area,
							filters,
							type
						}
					})
					setArea(json.area)
					setConf(json.conf)
					setType(json.type)
					setLabels(json.labels)
					setLegend(json.legend)

					const labels = { ...json.labels }
					const label = labels[json.type]
					if (label) {
						labels[json.type] = labels.value
						labels.value = label
					}
					for (const feature of json.features) {
						feature.properties.stack = json.stack
						feature.properties.unit = json.legend.unit
						feature.properties.labels = labels
					}

					setFeatures(json.features)
					return { area: json.area, features: json.features }
				} catch (_) {
					return { area, features: [] }
				}
			}
		}
	}
}
