// preact
import { FunctionComponent } from 'preact'
import { useContext, useEffect, useRef, useState } from 'preact/hooks'

// styles
import 'mapbox-gl/dist/mapbox-gl.css'
import './app.scss'

// stores
import AreasStore from 'stores/areas'
import FiltersStore, { Filters } from 'stores/filters'

// components
import AppHeader from 'components/AppHeader'
import Footer from 'components/Footer'
import PageHeader from 'components/PageHeader'
import TabPanel from 'components/TabPanel'
import ResizablePanels from 'components/ResizablePanels'
import Query from 'components/Query'
import About from 'components/About'
import Instructions from 'components/Instructions'
import Empty from 'components/Empty'

// views
import MapView from 'views/MapView'
import GraphView from 'views/GraphView'
import TrendView from 'views/TrendView'
import TableView from 'views/TableView'

// constants
declare const BASE_URL: string
import { HOST } from 'stores/api'

const App: FunctionComponent = () => {
	const areasStore = useContext(AreasStore.Context)
	const filtersStore = useContext(FiltersStore.Context)

	const area = filtersStore.area ? areasStore.areas.find(area => area.name === filtersStore.area) : undefined
	const areaName = area?.name ?? ''
	const areaLabel = area?.label ?? ''
	const selected = filtersStore.selected
	const selectedKeys = Object.keys(selected)
	const titles = filtersStore.selectors.filter(({ group }) => selectedKeys.includes(group) && (group === 'event' || group === 'year' || group === 'type'))
		.map(({ group, options }) => options.find(o => o.value === selected[group][0])?.label ?? '')
	const title = [ titles?.[0], titles?.[2], titles?.[1] ].filter(_ => _).join(', ')
	const description = filtersStore.selectors.filter(({ group }) => selectedKeys.includes(group) && group !== 'event' && group !== 'year' && group !== 'type')
		.map(({ group, options }) => options.find(o => o.value === selected[group][0])?.label ?? '').join(', ')

	const aboutRef = useRef<HTMLElement>(null)
	const queryRef = useRef<HTMLElement>(null)

	const [ showQuery, setShowQuery ] = useState(true)
	const [ showAbout, setShowAbout ] = useState(false)

	useEffect(() => {
		areasStore.list()

		const onMouseDown = (event: MouseEvent) => {
			if (aboutRef.current && !aboutRef.current.contains(event.target as Node)) {
				setShowAbout(false)
			}
			if (queryRef.current && !queryRef.current.contains(event.target as Node)) {
				setShowQuery(false)
			}
		}
		document.addEventListener('mousedown', onMouseDown)
		return () => {
			document.removeEventListener('mousedown', onMouseDown)
		}
	}, [])

	useEffect(() => {
		const query = new URLSearchParams(window.location.search)
		const hasAreaSelected = filtersStore.area && filtersStore.querySelected
		if (hasAreaSelected && filtersStore.init) {
			query.delete('area')
			if (query.size) {
				filtersStore.filter([ ...query.entries() ]
						.reduce<Filters>((o, [ k, v ]) => (o[k.toLowerCase()] = v.split(',').filter(v => v), o), {}),
					true)
			}
		} else if (hasAreaSelected) {
			window.history.pushState({ selected: filtersStore.selected }, '', window.location.origin + `?area=${filtersStore.area}&` + filtersStore.querySelected)
		} else if (areasStore.areas.length && query.has('area')) {
			const name = query.get('area')
			const area = areasStore.areas.find(area => area.name === name)
			if (area) {
				filtersStore.setArea(area, true)
			}
		}
	}, [ areasStore.areas.length, filtersStore.area, filtersStore.querySelected ])

	const [ showPanels, setShowPanels ] = useState(false)
	const [ showHelp, setShowHelp ] = useState(true)
	const [ selectedLeft, setSelectedLeft ] = useState('map')
	const [ selectedRight, setSelectedRight ] = useState('chart')

	const exportDataUrl = filtersStore.area && filtersStore.querySelected ?
		new URL('explorer/table' + `?area=${filtersStore.area}&` + filtersStore.querySelected, HOST).toString() : undefined

	const populated = !!area
	return <>
		<AppHeader onHelpClick={() => setShowHelp(true)} onQueryClick={() => setShowQuery(true)}/>
		<main>
			<Query ref={queryRef} showQuery={showQuery} close={() => setShowQuery(false)}/>
			<Empty hidden={populated} background={'/images/empty.webp'}
			       button={{ onClick: () => setShowQuery(true), label: 'Modify Query', icon: 'edit' }}>Choose a Topic to Get Started</Empty>
			{populated && <>
				<section className="views" data-populated={populated}>
					<PageHeader
						area={areaLabel}
						title={title}
						description={description}
						exportDataUrl={exportDataUrl}
						onAboutClick={() => setShowAbout(true)}/>
					<ResizablePanels showPanels={showPanels}>
						<TabPanel selected={selectedLeft} onSelect={(id) => (setSelectedLeft(id), selectedRight === id && setSelectedRight(selectedLeft))}
						          showPanels={showPanels} setShowPanels={setShowPanels}>
							<MapView id="map" label="Map View" icon="map"
							         topic={areaLabel} event={titles[0]} year={titles[2]} disabled={showPanels && selectedRight === 'map'}/>
							<GraphView id="chart" label="Chart View" icon="bar_chart"
							           topic={areaLabel} event={titles[0]} year={titles[2]}
							           disabled={showPanels && selectedRight === 'chart'}/>
							<TrendView id="trend" label="Time Trend" icon="timeline"
							           topic={areaLabel} event={titles[0]} year={titles[2]}
							           disabled={showPanels && selectedRight === 'trend'}/>
							<TableView id="table" label="Table View" icon="table" disabled={showPanels && selectedRight === 'table'}/>
						</TabPanel>
						<TabPanel selected={selectedRight} onSelect={(id) => (setSelectedRight(id), selectedLeft === id && setSelectedLeft(selectedRight))}
						          showPanels={showPanels} setShowPanels={setShowPanels}>
							<MapView id="map" label="Map View" icon="map"
							         topic={areaLabel} event={titles[0]} year={titles[2]} disabled={selectedLeft === 'map'}/>
							<GraphView id="chart" label="Chart View" icon="bar_chart"
							           topic={areaLabel} event={titles[0]} year={titles[2]} disabled={selectedLeft === 'chart'}/>
							<TrendView id="trend" label="Time Trend" icon="timeline"
							           topic={areaLabel} event={titles[0]} year={titles[2]} disabled={selectedLeft === 'trend'}/>
							<TableView id="table" label="Table View" icon="table" disabled={selectedLeft === 'table'}/>
						</TabPanel>
					</ResizablePanels>
				</section>
				<About ref={aboutRef} src={new URL(areaName, BASE_URL).toString()} show={showAbout} close={() => setShowAbout(false)}/>
			</>}
		</main>
		<Footer/>
		<Instructions isOpen={showHelp} onClose={() => setShowHelp(false)}/>
	</>
}

export default App
